import React from "react";
import { connect } from "react-redux";
import Joyride from "react-joyride";
import Tooltip from "../../components/Tooltip/Tooltip";
import { guideActions, authActions } from "../../actions";
import { bindActionCreators } from "redux";
import Avatar from "../../components/Avatar/Avatar";

class Guide extends React.Component {
  constructor(props) {
    super(props);
    this.props.guideActions.init();
  }

  styles = {
    spotlight: {
      outline: "3px solid red",
      borderRadius: 0
    },
    options: {
      overlayColor: "rgba(0, 0, 0, 0.6)"
    }
  };

  render() {
    let { styles } = this;
    let { guideActions, guide, authActions, user } = this.props;
    let attachActions = step => {
      return { ...step, ...guideActions };
    };
    return (
      <div>
        {guide.steps && !guide.minimalized && (
          <Joyride
            disableOverlayClose={true}
            spotlightClicks={true}
            spotlightPadding={0}
            styles={styles}
            steps={guide.steps.map(attachActions)}
            stepIndex={guide.current}
            continuous={true}
            tooltipComponent={Tooltip}
            floaterProps={{ offset: 30, hideArrow: true }}
          />
        )}
        {guide.minimalized && <Avatar {...{ authActions, guideActions, user }} />}
      </div>
    );
  }
}

const mapStateToProps = state => ({ guide: state.guide, user: state.auth });

const mapDispatchToProps = dispatch => ({
  guideActions: bindActionCreators(guideActions, dispatch),
  authActions: bindActionCreators(authActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Guide);
