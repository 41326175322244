import React from "react";
import { withTranslation } from "react-i18next";
import Icon from "../Icon";

let styles = {};
import(/* webpackMode: "eager" */
`./${process.env.REACT_APP_VENDOR}/Tile.module.scss`).then(importedStyles => {
  styles = importedStyles.default;
});

const Tile = ({ data, click, t, jobs, copiesLeft, user, credit, quota, customStyle }) => (
  <div
    className={
      (process.env.REACT_APP_VENDOR === "myq" && customStyle
        ? `A${customStyle}_${data.action}`
        : `A${data.action}`) +
      ` ${styles["k42-tile"]} ${
        (jobs && jobs.count <= 0) || (credit && credit <= 0) || (quota && quota <= 0)
          ? process.env.REACT_APP_VENDOR === "myq" && customStyle
            ? `${customStyle}_0`
            : "A0"
          : ""
      }`
    }
    onClick={() =>
      jobs && jobs.count > copiesLeft && copiesLeft ? alert("Not enough copies left") : click(data)
    }>
    <div className="k42-spacer" />
    <div className={styles["k42-tile-icons"]}>
      <Icon
        className={styles["k42-tile-logo"]}
        name={"A" + data.action}
        size="large"
        user={user.id}
      />
      {jobs && <h2 className={styles["k42-tile-attachment"]}>{jobs.count}</h2>}
    </div>
    <div className="k42-spacer" />
    <span className={styles["k42-tile-title"]}>
      {t(data.name)} <br />
      {jobs && jobs.sum}
    </span>
  </div>
);

export default withTranslation()(Tile);
