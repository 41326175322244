import React from "react";
import { withTranslation } from "react-i18next";
import Login from "../Login";
import Error from "../../components/Error";
import Dashboard from "../Dashboard";
import Jobs from "../Jobs";
import PrivateRoute from "../../components/PrivateRoute";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import * as ons from "onsenui";
import styles from "./App.module.scss";
import "onsenui/css/onsenui.css";
import "onsenui/css/onsen-css-components.css";
import ApplicationList from "../ApplicationList";
import MediaQuery from "react-responsive";
import Icon from "../../components/Icon";
import Animation from "../../components/Animation";
import Guide from "../Guide";
import getLogoUrlByWidth from "../../common/utils/getLogoUrlByWidth";
import trackerGA from "../../common/utils/trackerGA";
import trackerPardot from "../../common/utils/trackerPardot";

ons.platform.select("android");
trackerPardot();

class App extends React.Component {
  render() {
    const { store, t, history } = this.props;
    trackerGA(history);

    return (
      <div>
        <MediaQuery query="(orientation: landscape)">
          <Provider store={store}>
            <div className={styles["k42-app"]}>
              <div id="joyride-root-target" />
              <Guide />
              <Switch>
                <PrivateRoute exact path="/dashboard" component={Dashboard} />
                <PrivateRoute exact path="/jobs" component={Jobs} />
                <PrivateRoute exact path="/application-list" component={ApplicationList} />
                <PrivateRoute exact path="/animation/:type/:count" component={Animation} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/login-failed/:message/:cause" component={Error} />
                <Route exact path="/">
                  <Redirect to={`/login`} />
                </Route>
                <Route path="/(.*)">
                  <Redirect to={`/login`} />
                </Route>
              </Switch>
            </div>
          </Provider>
        </MediaQuery>
        <MediaQuery query="(orientation: portrait)">
          <div className={styles["k42-prompt-rotate"]}>
            <img
              alt="MyQ Logo"
              className={styles["k42-login-logo"]}
              src={getLogoUrlByWidth(1024)}
              srcSet={`${getLogoUrlByWidth(600)} 600w, ${getLogoUrlByWidth(
                1024
              )} 1024w, ${getLogoUrlByWidth(1920)} 1920w`}
            />
            <Icon name="rotate" size="large" />
            <p>{t("Please rotate your device")}</p>
          </div>
        </MediaQuery>
      </div>
    );
  }
}

export default withRouter(withTranslation()(App));
