import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./containers/App/App";
import storeFactory from "./services/storeFactory";
import { BrowserRouter as Router } from "react-router-dom";
import * as serviceWorker from "./services/serviceWorker";
import "./i18n";
import(`./index.${process.env.REACT_APP_VENDOR}.scss`);

export let store = storeFactory({});
let rootElement = document.getElementById("root");

ReactDOM.render(
  <Router>
    <Suspense fallback={<div />}>
      <App store={store} />
    </Suspense>
  </Router>,
  rootElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below.
serviceWorker.unregister();
