import * as React from "react";
import { Trans } from "react-i18next";
import { INFO } from "../../constants/General";
import isMobile from "../../common/utils/isMobile";
import Icon from "../Icon";
import Bubble from "../Bubble";
import TooltipEmail from "../TooltipEmail";

let styles = {};
import(/* webpackMode: "eager" */
`./${process.env.REACT_APP_VENDOR}/Tooltip.module.scss`).then(importedStyles => {
  styles = importedStyles.default;
});

class Tooltip extends React.Component {
  element;
  autofillElement;

  componentDidMount() {
    this.watcher(this.props.step.target, "input", this.handleInputChange);
    this.watcher(this.props.step.clickTarget, "click", this.handleClick);
  }

  watcher = (target, type, callback) => {
    if (target) {
      this.element =
        document.getElementById(target.replace("#", "")) ||
        document.getElementsByName(target)[0] ||
        document.getElementsByClassName(target.replace(".", ""))[0];
      if (this.element) {
        this.element.addEventListener(type, callback);
        this.element.focus();
      }
    }
  };

  handleInputChange = event => {
    // console.log(event, this.props);
    if (
      this.props.step.autofill &&
      event.target.value.toString() === this.props.step.autofill.toString()
    ) {
      if (this.props.step.inputTarget && isMobile.any()) {
        this.element =
          document.getElementById(this.props.step.inputTarget.replace("#", "")) ||
          document.getElementsByName(this.props.step.inputTarget)[0] ||
          document.getElementsByClassName(this.props.step.inputTarget.replace(".", ""))[0];
      }
      this.autoRedirect();
      this.element.removeEventListener("input", this.handleInputChange);
    }
  };

  handleClick = event => {
    // this.autoRedirect();
    this.element.removeEventListener("click", this.handleInputChange);
  };

  // https://github.com/facebook/react/issues/10135#issuecomment-314441175
  setNativeValue = (element, value) => {
    const valueSetter = Object.getOwnPropertyDescriptor(element, "value").set;
    const prototype = Object.getPrototypeOf(element);
    const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, "value").set;

    if (valueSetter && valueSetter !== prototypeValueSetter) {
      prototypeValueSetter.call(element, value);
    } else {
      valueSetter.call(element, value);
    }
  };

  handleNextClick = event => {
    const { step } = this.props;
    if (step.autofill && step.inputTarget) {
      if (isMobile.any()) {
        this.element =
          document.getElementById(step.inputTarget.replace("#", "")) ||
          document.getElementsByName(step.inputTarget)[0] ||
          document.getElementsByClassName(step.inputTarget.replace(".", ""))[0];
      }
      this.setNativeValue(this.element, step.autofill);
      this.element.dispatchEvent(new Event("input", { bubbles: true }));
      return;
    }
    if (step.clickTarget) {
      this.element.click();
      return;
    }
    step.next();
  };

  autoRedirect = () => {
    setTimeout(() => {
      setTimeout(() => {
        this.element.blur();
      }, 0);
      this.props.step.next();
    }, 0);
  };

  render() {
    let { tooltipProps, step, continuous } = this.props;
    return (
      <Bubble arrow={step.arrow} skip={step.skip} className={styles.bubble}>
        <div {...tooltipProps} className={`react-joyride__tooltip ${styles.parent}`}>
          {step.title && (
            <h4 className={styles.title}>
              <Trans>{step.title}</Trans>
            </h4>
          )}
          {step.content && (
            <div className={styles.content}>
              <Trans>{step.content}</Trans>
            </div>
          )}
          {step.tooltipEmail && (
            <div className={styles.content}>
              <TooltipEmail />
            </div>
          )}
          {step.info && (
            <div className={styles.infoBox}>
              <Icon name={INFO} className={styles.infoIcon} />
              <Trans>{step.info}</Trans>
            </div>
          )}
          {step.autofill && (
            <div className={styles.autoFillContainer}>
              <span className={styles.autoFillText}>
                <Trans>Type this into the text field</Trans>
              </span>
              <div className={styles.autoFillBox}>{step.autofill}</div>
            </div>
          )}
          {step.img && (
            <img
              className={styles.attachmentImage}
              src={`${process.env.PUBLIC_URL}/img/${step.img}`}
              alt=""
            />
          )}
          <div className={styles.buttonRow}>
            {step.showSkipButton && (
              <button onClick={step.skip} className={styles.secondaryButton}>
                <Trans>Skip guide</Trans>
              </button>
            )}
            <div className={styles.spacer} />
            {continuous && (
              <button onClick={this.handleNextClick} className={styles.primaryButton}>
                <Trans>Next</Trans>
              </button>
            )}
            {!continuous && !step.autofill && !step.clickTarget && (
              <button onClick={step.skip} className={styles.primaryButton}>
                <Trans>Close</Trans>
              </button>
            )}
          </div>
        </div>
      </Bubble>
    );
  }
}

export default Tooltip;
