import React from "react";
import { withTranslation } from "react-i18next";
import styles from "./Animation.module.scss";

const Animation = ({ match, history, t }) => {
  const pathRegEx = /\/animation/g;

  setTimeout(() => {
    if (pathRegEx.test(history.location.pathname)) history.goBack();
  }, 2000 * match.params.count);

  return (
    <div className={styles["k42-animation"]}>
      {match.params.type === "print" && (
        <div className={styles["k42-animation-container"]}>
          <img
            className={styles["k42-page-print"]}
            alt={t(`Printed Page`)}
            src={`${process.env.PUBLIC_URL}/img/paper.png`}
          />
        </div>
      )}
      {match.params.type === "scan" && (
        <div className={styles["k42-animation-container"]}>
          <div className={styles["k42-page-scan"]} />
          <img
            className={styles["k42-page"]}
            alt={t(`Printed Page`)}
            src={`${process.env.PUBLIC_URL}/img/paper.png`}
          />
        </div>
      )}
      {match.params.type === "copy" && (
        <div className={styles["k42-animation-container"]}>
          <div className={styles["k42-page-scan"]} />
          <img
            className={styles["k42-page-print"]}
            alt={t(`Printed Page`)}
            src={`${process.env.PUBLIC_URL}/img/paper.png`}
          />
        </div>
      )}
    </div>
  );
};
export default withTranslation()(Animation);
