import { combineReducers } from "redux";
import auth from "./auth";
import menu from "./menu";
import dialog from "./dialog";
import jobs from "./jobs";
import guide from "./guide";

const rootReducer = combineReducers({
  auth,
  menu,
  dialog,
  jobs,
  guide
});

export default rootReducer;
