import ReactGA from "react-ga";

export default store => next => action => {
  ReactGA.event({
    category: "Interaction",
    action: action.type,
    label: action.message
  });
  next(action);
};
