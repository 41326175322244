import React from "react";
import DialPad from "../DialPad";
import QrLogin from "../QrLogin";
import Icon from "../Icon";
import { ENTER, DEL } from "../../constants/General";
import isMobile from "../../common/utils/isMobile";
import { Row, Button, Col, Card } from "react-onsenui";
import getLogoUrlByWidth from "../../common/utils/getLogoUrlByWidth";
import getPoweredLogoUrlByWidth from "../../common/utils/getPoweredLogoUrlByWidth";
import { withTranslation } from "react-i18next";

let styles = {};
import(/* webpackMode: "eager" */
`./${process.env.REACT_APP_VENDOR}/LoginPanel.module.scss`).then(importedStyles => {
  styles = importedStyles.default;
});

class LoginPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      focused: 1
    };
  }

  handleChange = change => {
    this.setState(change);
  };

  dialPadCallback = key => {
    switch (this.state.focused) {
      case 0:
        this.setState({
          username: this.editValue(this.state.username, key)
        });
        break;
      case 1:
        this.setState({
          password: this.editValue(this.state.password, key)
        });
        break;
      default:
        break;
    }
    if (key === ENTER) this.handleLogin();
  };

  editValue = (value, key) => {
    switch (key) {
      case DEL:
        return value.slice(0, value.length - 1);
      case ENTER:
        return value;
      default:
        return value + key;
    }
  };

  handleLogin = () => {
    this.props.login(this.state.username, this.state.password);
  };

  handleQrLogin = () => {
    this.props.login("", "11");
  };

  addPoweredByLogo = () => {
    if (
      process.env.REACT_APP_VENDOR !== "myq" &&
      process.env.REACT_APP_VENDOR !== "knm" &&
      process.env.REACT_APP_VENDOR !== "ta" &&
      process.env.REACT_APP_VENDOR !== "utax"
    ) {
      return (
        <img
          id="poweredBy"
          alt="powered by MyQ Logo"
          className={styles["k42-poweredBy-logo-for-" + process.env.REACT_APP_VENDOR]}
          src={getPoweredLogoUrlByWidth(1024)}
          srcSet={`${getPoweredLogoUrlByWidth(600)} 2048w, ${getPoweredLogoUrlByWidth(
            1024
          )} 4096, ${getPoweredLogoUrlByWidth(1920)} 7000w`}
        />
      );
    } else if (
      process.env.REACT_APP_VENDOR === "knm" ||
      process.env.REACT_APP_VENDOR === "ta" ||
      process.env.REACT_APP_VENDOR === "utax"
    ) {
      return <div />;
    }
  };

  render() {
    const { t } = this.props;
    return (
      <Card className={styles["k42-login-card"]}>
        <img
          alt={process.env.REACT_APP_VENDOR + " Logo"}
          className={styles["k42-login-logo"]}
          src={getLogoUrlByWidth(1024)}
          srcSet={`${getLogoUrlByWidth(600)} 2048w, ${getLogoUrlByWidth(
            1024
          )} 4096w, ${getLogoUrlByWidth(1920)} 7000w`}
        />
        {this.addPoweredByLogo()}
        <form>
          <Row>
            <Col className={styles["k42-login-col"]}>
              <div className={styles["k42-login-form"]}>
                <Icon className={styles["k42-login-input-icon"]} name={"keyboard"} size="small" />
                <Icon className={styles["k42-login-input-icon"]} name={"keyboard"} size="small" />
                <input
                  type="text"
                  className={styles["k42-login-input"]}
                  autoComplete="myq-username"
                  value={this.state.username}
                  onChange={event =>
                    this.handleChange({
                      username: event.target.value
                    })
                  }
                  onClick={() => this.setState({ focused: 0 })}
                  placeholder={t("User name")}
                />
                {isMobile.any() ? (
                  <div id="k42-step-1" className={styles["k42-login-input-mobile"]}>
                    <input
                      id="k42-step-1-input"
                      type="password"
                      autoComplete="myq-password"
                      value={this.state.password}
                      onChange={event =>
                        this.handleChange({
                          password: event.target.value
                        })
                      }
                      onClick={() => this.setState({ focused: 1 })}
                      placeholder={t("Password / PIN")}
                    />
                  </div>
                ) : (
                  <input
                    id="k42-step-1"
                    type="password"
                    className={styles["k42-login-input"]}
                    autoComplete="myq-password"
                    value={this.state.password}
                    onChange={event =>
                      this.handleChange({
                        password: event.target.value
                      })
                    }
                    onClick={() => this.setState({ focused: 1 })}
                    placeholder={t("Password")}
                  />
                )}

                <Button
                  id="k42-step-2"
                  className={`${styles["k42-login-button"]}`}
                  onClick={this.handleLogin}>
                  {t("OK")}
                </Button>
                <span className={styles["k42-login-disclaimer"]}>
                  {t("Enter PIN, name and password, or present a card to log in")}
                </span>
              </div>
            </Col>
            <Col className={styles["k42-login-col"]}>
              <div className={styles["k42-dialpad"]}>
                {this.props.dialpad ? (
                  <DialPad callback={this.dialPadCallback} />
                ) : (
                  <QrLogin handleQrLogin={this.handleQrLogin} />
                )}
              </div>
            </Col>
          </Row>
        </form>
      </Card>
    );
  }
}

export default withTranslation()(LoginPanel);
