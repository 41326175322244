import React from "react";
import { Page, Input } from "react-onsenui";
import styles from "./Dialog.module.scss";

const DialogPrompt = ({ fields, handleAction, password }) => {
  return (
    <Page className={styles["k42-dialog-prompt"]}>
      <section>
        <Input
          float
          className={styles["k42-dialog-prompt-input"]}
          value=""
          type={password ? "password" : "text"}
          onChange={event => {
            handleAction(event.target.value);
          }}
          modifier="material"
          placeholder=""
        />
      </section>
    </Page>
  );
};

export default DialogPrompt;
