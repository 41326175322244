import React from "react";
import { withTranslation } from "react-i18next";
import styles from "./JobList.module.scss";
import { List } from "react-onsenui";
import {
  PRINTED,
  FAVORITE,
  READY,
  UNMARK_FAVORITE,
  MARK_FAVORITE,
  DUPLICATE,
  PRINT
} from "../../constants/General";
import JobListRow from "./JobListRow";

const JobList = ({ list, activeTab, toggleRow, action, t, country }) => {
  const isDisplayed = (job, activeTab) => {
    switch (activeTab.action) {
      case PRINTED:
        return !job.deleted && job.printed;
      case FAVORITE:
        return !job.deleted && job.favorite;
      case READY:
        return !job.deleted && !job.printed;
      default:
        return !job.deleted;
    }
  };

  const getFavoriteAction = () => {
    return activeTab.action === FAVORITE ? UNMARK_FAVORITE : MARK_FAVORITE;
  };

  const getPrintAction = job => {
    return job.printed ? DUPLICATE : PRINT;
  };

  const getActions = row => {
    row.printAction = getPrintAction(row);
    row.favoriteAction = getFavoriteAction();
    return row;
  };

  const isNoJobs = list => {
    return !list.reduce((acc, row) => !!acc || isDisplayed(row, activeTab), false);
  };

  const renderRow = (row, index) => {
    return (
      <JobListRow
        key={index}
        row={row}
        index={index}
        toggleRow={toggleRow}
        action={action}
        country={country}
      />
    );
  };

  return (
    <div className={styles["k42-job-list"]}>
      {!isNoJobs(list) && (
        <List
          dataSource={list.map(getActions).filter(row => isDisplayed(row, activeTab))}
          renderRow={(row, index) => renderRow(row, index)}
        />
      )}
      {isNoJobs(list) && <span className={styles["k42-nojobs"]}>{t("No Jobs")}</span>}
    </div>
  );
};

export default withTranslation()(JobList);
