const valueToName = {
  bs: "Bosanski (Bosna I Hercegovina)",
  cs: "Čeština (Česká Republika)",
  da: "Dansk (Danmark)",
  de: "Deutsch (Deutschland)",
  et: "Eesti (Eesti)",
  en: "English (United Kingdom)",
  "en-US": "English (United States)",
  es: "Español (España)",
  //"es-US": "Español (Estados Unidos)",
  fr: "Français (France)",
  hr: "Hrvatski (Hrvatska)",
  is: "Íslenska (Ísland)",
  it: "Italiano (Italia)",
  lv: "Latviešu (Latvija)",
  lt: "Lietuvių (Lietuva)",
  hu: "Magyar (Magyarország)",
  no: "Norsk (Norge)",
  pl: "Polski (Polska)",
  "pt-BR": "Português (Brasil)",
  pt: "Português (Portugal)",
  sk: "Slovenčina (Slovensko)",
  sl: "Slovenščina (Slovenija)",
  tr: "Türkçe (Türkiye)",
  bg: "Български (България)",
  kk: "Қазақ Тілі (Қазақстан)",
  ru: "Русский (Россия)",
  sr: "Српски (Србија)",
  ar: "العربية (المملكة العربية السعودية)",
  ko: "한국어(대한민국)",
  "zn-cn": "中文（简体）",
  "zn-tw": "中文（繁體）",
  ja: "日本語 (日本)"
};

export const langKeys = Object.keys(valueToName);
export default name => valueToName[name];
