import { ActionTypes } from "../constants/";
import i18next from "i18next";

const getUsersPath = () => {
  switch (process.env.REACT_APP_VENDOR) {
    case "myq":
      return process.env.PUBLIC_URL + "/data/users.json";
    case "ta":
    case "wietholt":
      return process.env.PUBLIC_URL + "/data/users.wietholt.json";
    case "knm":
      return process.env.PUBLIC_URL + "/data/users.knm.json";
    default:
      return process.env.PUBLIC_URL + "/data/users.json";
  }
};

export const loginUser = (username, password) => {
  return async dispatch => {
    const res = await fetch(getUsersPath());
    const json = res.json();
    const users = await json;
    const user = users.data.find(user => user.id === password);
    if (user) {
      dispatch(loginUserSuccess(user));
      const userLanguage = localStorage[user.username];
      if (userLanguage) {
        dispatch(changeLanguage(userLanguage));
      }
    } else {
      dispatch(loginUserFailed());
    }
  };
};

export const loginUserSuccess = user => {
  return {
    type: ActionTypes.LOGIN_SUCCESS,
    message: "Login successful",
    username: user.username,
    fullname: user.fullname,
    credit: user.credit,
    id: user.id,
    copiesLeft: user.copiesLeft,
    topmenutype: user.topmenutype,
    limit: user.limit,
    quota: user.quota,
    background: user.background,
    customStyle: user.customStyle
  };
};

export const changeLanguage = language => {
  return dispatch => {
    i18next.changeLanguage(language); // localStorage gets set automatically
    dispatch({
      type: ActionTypes.LANGUAGE_SUCCESS
    });
  };
};

export const changeAppLanguage = language => {
  setLocalStorage("appLng", language);
  return {
    type: ActionTypes.LANGUAGE_SUCCESS
  };
};

export const setLocalStorage = (item, value) => {
  localStorage.setItem(item, value);
};

export const getCurrentLanguage = () => {
  return i18next.language;
};

export const loginUserFailed = () => ({
  type: ActionTypes.LOGIN_FAILURE,
  message: "Login failed",
  cause: "Unknown User"
});

export const logoutUser = () => {
  return dispatch => {
    dispatch(changeLanguage(localStorage.appLng));
    dispatch({
      type: ActionTypes.LOGOUT_SUCCESS
    });
  };
};

export const changeQuota = jobCount => ({
  type: ActionTypes.CHANGE_QUOTA,
  jobCount
});

export const changeCredit = sum => ({
  type: ActionTypes.CHANGE_CREDIT,
  sum
});
