import React from "react";
import getFormattedPrice from "../../common/utils/getFormattedPrice";
import getCountryInfoByLanguage from "../../common/utils/getCountryInfoByLanguage";
import { getCurrentLanguage } from "../../actions/auth";
let styles = {};

import(/* webpackMode: "eager" */
`./${process.env.REACT_APP_VENDOR}/Navbar.module.scss`).then(importedStyles => {
  styles = importedStyles.default;
});

const NavbarAdditions = ({ user }) => {
  switch (user.fullname) {
    case "Quota User":
      return (
        <div>
          <h2
            className={styles["k42-navbar-header"]}
            style={{ marginTop: "0px", marginBottom: "0px" }}>
            {user.fullname}
          </h2>
          <p className={styles["k42-navbar-quota"]}>
            Color Print {user.copiesLeft ? Math.round((user.copiesLeft / user.quota) * 100) : 100}%{" "}
            {user.copiesLeft}/{user.quota}
          </p>
        </div>
      );
    case "Credit User":
      return (
        <div>
          <h2
            className={styles["k42-navbar-header"]}
            style={{ marginTop: "0px", marginBottom: "0px" }}>
            {user.fullname}
          </h2>
          <p className={styles["k42-navbar-quota"]}>
            Credit{" "}
            {getFormattedPrice(
              (
                getCountryInfoByLanguage(getCurrentLanguage()) || {
                  countryCode: "US",
                  currency: "USD"
                }
              ).currency,
              user.credit,
              (
                getCountryInfoByLanguage(getCurrentLanguage()) || {
                  countryCode: "US",
                  currency: "USD"
                }
              ).countryCode
            )}{" "}
            (limit{" "}
            {getFormattedPrice(
              (
                getCountryInfoByLanguage(getCurrentLanguage()) || {
                  countryCode: "US",
                  currency: "USD"
                }
              ).currency,
              user.limit,
              (
                getCountryInfoByLanguage(getCurrentLanguage()) || {
                  countryCode: "US",
                  currency: "USD"
                }
              ).countryCode
            )}
            )
          </p>
        </div>
      );
    default:
      return <h2 className={styles["k42-navbar-header"]}>{user.fullname}</h2>;
  }
};

export default NavbarAdditions;
