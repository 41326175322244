import { GUIDE_NEXT_STEP, GUIDE_BACK_STEP, SKIP_GUIDE, INIT_GUIDE } from "../constants/ActionTypes";

const initialState = {
  minimalized: false,
  steps: [],
  current: null
};

export default function guide(state = initialState, action) {
  switch (action.type) {
    case INIT_GUIDE:
      return {
        ...state,
        current: 0,
        minimalized: false,
        steps: action.steps
      };

    case GUIDE_NEXT_STEP:
      return {
        ...state,
        current: state.current + 1,
        minimalized: state.current + 1 >= state.steps.length
      };

    case GUIDE_BACK_STEP:
      return {
        ...state,
        current: state.current - 1,
        minimalized: false
      };

    case SKIP_GUIDE:
      return {
        ...state,
        current: state.steps.length,
        minimalized: true
      };

    default:
      return state;
  }
}
