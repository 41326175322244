import React from "react";
import { dialogActions } from "../../actions/index";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Dialog as DialogComponent } from "../../components/Dialog";

class Dialog extends React.Component {
  setActiveTab = index => {
    this.props.dialogActions.setActiveTab(index);
  };

  handleAction = (id, value, name) => {
    switch (id) {
      case 104:
        this.props.dialogActions.closeDialog();
        break;
      case 105:
        this.props.dialogActions.saveValue(name, value);
        this.props.dialogActions.closeDialog();
        break;
      case 106:
        this.props.dialogActions.closeDialog();
        this.props.scan();
        break;
      case 107:
        this.props.dialogActions.closeDialog();
        this.props.print();
        break;
      default:
        if (id) this.props.dialogActions.openDialog(`A${id}`, value);
    }
  };

  render() {
    return this.props.dialogs.map((dialog, index) => (
      <DialogComponent
        key={index}
        setActiveTab={this.setActiveTab}
        handleAction={this.handleAction}
        opened={dialog.opened}
        label={dialog.label}
        activeTab={dialog.activeTab}
        buttons={dialog.buttons}
        type={dialog.type}
        content={dialog.content}
        dialogActions={this.props.dialogActions}
      />
    ));
  }
}

const mapStateToProps = state => ({ dialogs: state.dialog });

const mapDispatchToProps = dispatch => ({
  dialogActions: bindActionCreators(dialogActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dialog);
