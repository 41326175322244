import * as React from "react";
class TooltipEmail extends React.Component {
  constructor(props) {
    super(props);

    this.state = { loaded: false };
    this.iframe = this.iframe.bind(this);
  }

  iframe() {
    this.setState({ loaded: true });
  }
  render() {
    return (
      <div>
        <iframe
          title="EmailFrame"
          src="https://go.myq-solution.com/l/139201/2019-08-21/2hqyhp"
          frameBorder="0"
          onLoad={this.iframe}
          height="75px"
          style={{ marginBottom: "-60px", display: this.state.loaded ? "block" : "none" }}
        />
        {!this.state.loaded && <div style={{ textAlign: "center" }}>Loading...</div>}
      </div>
    );
  }
}

export default TooltipEmail;
