import { ActionTypes } from "../constants/";
export const getJobs = () => {
  return async dispatch => {
    const res = await fetch(`${process.env.PUBLIC_URL}/data/jobs_ready.json`);
    const json = await res.json();
    const jobs = json.jobs;
    if (jobs) {
      await dispatch(getJobsSuccess(jobs));
    } else {
      await dispatch(getJobsFailure());
    }
  };
};

export const toggleJob = job => ({
  type: ActionTypes.TOGGLE_JOB_SUCCESS,
  message: "New row selected",
  job: job
});

export const toggleJobAllJobs = () => ({
  type: ActionTypes.TOGGLE_ALL_JOBS_SUCCESS,
  message: "All rows toggled"
});

export const printJobsRequest = () => ({
  type: ActionTypes.PRINT_JOBS_REQUEST,
  message: "Jobs printed"
});

export const printJobsSuccess = jobsCount => ({
  type: ActionTypes.PRINT_JOBS_SUCCESS,
  message: "Jobs printed",
  jobsCount
});

export const duplicateJobsRequest = () => ({
  type: ActionTypes.DUPLICATE_JOBS_REQEUST,
  message: "Jobs added"
});

export const duplicateJobsSuccess = () => ({
  type: ActionTypes.DUPLICATE_JOBS_SUCCESS,
  message: "Jobs added"
});

export const favoritJobsRequest = () => ({
  type: ActionTypes.FAVORITE_JOBS_REQUEST,
  message: "Jobs favorited."
});

export const favoritJobsSuccess = () => ({
  type: ActionTypes.FAVORITE_JOBS_SUCCESS,
  message: "Jobs favorited."
});

export const unfavoritJobsRequest = () => ({
  type: ActionTypes.UNFAVORITE_JOBS_REQUEST,
  message: "Jobs favorited."
});

export const unfavoritJobsSuccess = () => ({
  type: ActionTypes.UNFAVORITE_JOBS_SUCCESS,
  message: "Jobs favorited."
});

export const deleteJobsRequest = () => ({
  type: ActionTypes.DELETE_JOBS_REQUEST,
  message: "Jobs deleted."
});

export const deleteJobsSuccess = jobsCount => ({
  type: ActionTypes.DELETE_JOBS_SUCCESS,
  message: "Jobs deleted.",
  jobsCount
});

export const selectTab = tab => ({
  type: ActionTypes.SELECT_TAB_SUCCESS,
  message: "New tab selected",
  tab: tab
});

const getJobsSuccess = (jobs, formattedSum) => ({
  type: ActionTypes.GET_JOBS_SUCCESS,
  message: "Gathering jobs successful",
  jobs,
  formattedSum
});

const getJobsFailure = () => ({
  type: ActionTypes.GET_JOBS_FAILURE,
  message: "Gathering jobs failed",
  cause: "Unknown type of top jobs"
});
