import React from "react";
import { withTranslation } from "react-i18next";
import styles from "./Error.module.scss";

const getImagePrefix = () => {
  switch (process.env.REACT_APP_VENDOR) {
    case "myq":
      return "myq";
    case "ta":
      return "ta";
    case "utax":
      return "utax";
    default:
      return "myq";
  }
};

const Error = ({ match, history, t }) => (
  <div className={styles["k42-error"]}>
    <img
      alt={t("MyQ Logo")}
      className={styles["k42-login-logo"]}
      src={`${process.env.PUBLIC_URL}/img/${getImagePrefix()}_logo.png`}
    />
    <div>{t(match.params.message)}</div>
    <div>{t(match.params.cause)}</div>
  </div>
);

export default withTranslation()(Error);
