export const ENTER = "enter";
export const DEL = "del";
export const DIALPAD = "dialpad";
export const QR = "qr";

export const JOB_FAVOURITE = "JOB_FAVOURITE";
export const JOB_COLOR = "JOB_COLOR";
export const JOB_DUPLEX = "JOB_DUPLEX";

// Jobs - Tabs
export const FAVORITE = "FAVORITE";
export const PRINTED = "PRINTED";
export const READY = "READY";
// JOBS - Tab Actions
export const DELETE = "DELETE";
export const PRINT = "PRINT";
export const DUPLICATE = "DUPLICATE";
export const MARK_FAVORITE = "MARK_FAVORITE";
export const UNMARK_FAVORITE = "UNMARK_FAVORITE";
export const SELECT_ALL = "SELECT_ALL";
export const UNSELECT_ALL = "UNSELECT_ALL";
export const TOGGLE = "TOGGLE";
export const INFO = "INFO";
export const SETTINGS = "SETTINGS";
