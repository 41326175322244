const exchangeRatesCZK = {
  usd: {
    code: "USD",
    alphaCode: "USD",
    numericCode: "840",
    name: "U.S. Dollar",
    rate: 0.042432092649739,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 23.567067696958
  },
  eur: {
    code: "EUR",
    alphaCode: "EUR",
    numericCode: "978",
    name: "Euro",
    rate: 0.038756910917459,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 25.801849949541
  },
  gbp: {
    code: "GBP",
    alphaCode: "GBP",
    numericCode: "826",
    name: "U.K. Pound Sterling",
    rate: 0.034374146443832,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 29.091631457206
  },
  chf: {
    code: "CHF",
    alphaCode: "CHF",
    numericCode: "756",
    name: "Swiss Franc",
    rate: 0.0421203614979,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 23.741486645357
  },
  aud: {
    code: "AUD",
    alphaCode: "AUD",
    numericCode: "036",
    name: "Australian Dollar",
    rate: 0.062771878294954,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 15.930700612481
  },
  cad: {
    code: "CAD",
    alphaCode: "CAD",
    numericCode: "124",
    name: "Canadian Dollar",
    rate: 0.056255394516358,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 17.776073007704
  },
  jpy: {
    code: "JPY",
    alphaCode: "JPY",
    numericCode: "392",
    name: "Japanese Yen",
    rate: 4.5658348078178,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.21901799826129
  },
  pln: {
    code: "PLN",
    alphaCode: "PLN",
    numericCode: "985",
    name: "Polish Zloty",
    rate: 0.16974708085477,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 5.8911175082627
  },
  try: {
    code: "TRY",
    alphaCode: "TRY",
    numericCode: "949",
    name: "Turkish Lira",
    rate: 0.24140379209074,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 4.1424369987697
  },
  bdt: {
    code: "BDT",
    alphaCode: "BDT",
    numericCode: "050",
    name: "Bangladeshi taka",
    rate: 3.5449804150634,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.2820890055558
  },
  tnd: {
    code: "TND",
    alphaCode: "TND",
    numericCode: "788",
    name: "Tunisian Dinar",
    rate: 0.11937247172675,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 8.3771407723638
  },
  svc: {
    code: "SVC",
    alphaCode: "SVC",
    numericCode: "222",
    name: "Salvadoran colon",
    rate: 0.37355895004686,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 2.6769536638717
  },
  awg: {
    code: "AWG",
    alphaCode: "AWG",
    numericCode: "533",
    name: "Aruban florin",
    rate: 0.07723191803302,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 12.94801457051
  },
  zmw: {
    code: "ZMW",
    alphaCode: "ZMW",
    numericCode: "967",
    name: "Zambian kwacha",
    rate: 0.56089266683594,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 1.7828723018276
  },
  yer: {
    code: "YER",
    alphaCode: "YER",
    numericCode: "886",
    name: "Yemeni rial",
    rate: 10.635080385805,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.094028438312019
  },
  dzd: {
    code: "DZD",
    alphaCode: "DZD",
    numericCode: "012",
    name: "Algerian Dinar",
    rate: 5.0818724844101,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.19677786152009
  },
  sgd: {
    code: "SGD",
    alphaCode: "SGD",
    numericCode: "702",
    name: "Singapore Dollar",
    rate: 0.058527722492597,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 17.085920268408
  },
  idr: {
    code: "IDR",
    alphaCode: "IDR",
    numericCode: "360",
    name: "Indonesian Rupiah",
    rate: 600.27235800002,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.0016659104599315
  },
  kzt: {
    code: "KZT",
    alphaCode: "KZT",
    numericCode: "398",
    name: "Kazakhstani Tenge",
    rate: 16.456605658726,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.060765872424595
  },
  gel: {
    code: "GEL",
    alphaCode: "GEL",
    numericCode: "981",
    name: "Georgian lari",
    rate: 0.12336204807446,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 8.1062207997423
  },
  stn: {
    code: "STN",
    alphaCode: "STN",
    numericCode: "930",
    name: "S\u00e3o Tom\u00e9 and Pr\u00edncipe Dobra",
    rate: 0.96548663933787,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 1.0357471136895
  },
  djf: {
    code: "DJF",
    alphaCode: "DJF",
    numericCode: "262",
    name: "Djiboutian franc",
    rate: 7.596282467128,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.131643340585
  },
  all: {
    code: "ALL",
    alphaCode: "ALL",
    numericCode: "008",
    name: "Albanian lek",
    rate: 4.7153042256039,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.21207539368723
  },
  khr: {
    code: "KHR",
    alphaCode: "KHR",
    numericCode: "116",
    name: "Cambodian riel",
    rate: 175.92424596828,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.0056842648066844
  },
  bnd: {
    code: "BND",
    alphaCode: "BND",
    numericCode: "096",
    name: "Brunei Dollar",
    rate: 0.0584371296328,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 17.112407920849
  },
  php: {
    code: "PHP",
    alphaCode: "PHP",
    numericCode: "608",
    name: "Philippine Peso",
    rate: 2.2202491481374,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.450399902569
  },
  tmt: {
    code: "TMT",
    alphaCode: "TMT",
    numericCode: "934",
    name: "New Turkmenistan Manat",
    rate: 0.14785038996391,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 6.7635939292691
  },
  pyg: {
    code: "PYG",
    alphaCode: "PYG",
    numericCode: "600",
    name: "Paraguayan Guaran\u00ed",
    rate: 271.89822598031,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.0036778467251655
  },
  fjd: {
    code: "FJD",
    alphaCode: "FJD",
    numericCode: "242",
    name: "Fiji Dollar",
    rate: 0.09309842163374,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 10.741320663138
  },
  cdf: {
    code: "CDF",
    alphaCode: "CDF",
    numericCode: "976",
    name: "Congolese franc",
    rate: 70.941929045594,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.014096036201064
  },
  szl: {
    code: "SZL",
    alphaCode: "SZL",
    numericCode: "748",
    name: "Swazi lilangeni",
    rate: 0.63978467789144,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 1.5630258656643
  },
  npr: {
    code: "NPR",
    alphaCode: "NPR",
    numericCode: "524",
    name: "Nepalese Rupee",
    rate: 4.8530480359257,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.20605606880404
  },
  cop: {
    code: "COP",
    alphaCode: "COP",
    numericCode: "170",
    name: "Colombian Peso",
    rate: 145.94096384577,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.0068520857588469
  },
  bbd: {
    code: "BBD",
    alphaCode: "BBD",
    numericCode: "052",
    name: "Barbadian Dollar",
    rate: 0.084981397787117,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 11.767281146693
  },
  twd: {
    code: "TWD",
    alphaCode: "TWD",
    numericCode: "901",
    name: "New Taiwan Dollar ",
    rate: 1.3162359136093,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.75974222376131
  },
  uah: {
    code: "UAH",
    alphaCode: "UAH",
    numericCode: "980",
    name: "Ukrainian Hryvnia",
    rate: 1.0261666945325,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.97450054199588
  },
  bob: {
    code: "BOB",
    alphaCode: "BOB",
    numericCode: "068",
    name: "Bolivian Boliviano",
    rate: 0.29329155308071,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 3.4095765442137
  },
  lrd: {
    code: "LRD",
    alphaCode: "LRD",
    numericCode: "430",
    name: "Liberian dollar",
    rate: 8.8603513464237,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.11286234155981
  },
  ssp: {
    code: "SSP",
    alphaCode: "SSP",
    numericCode: "728",
    name: "South Sudanese pound",
    rate: 6.769548183499,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.14772034601032
  },
  ern: {
    code: "ERN",
    alphaCode: "ERN",
    numericCode: "232",
    name: "Eritrean nakfa",
    rate: 0.64317106181978,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 1.5547963199256
  },
  sos: {
    code: "SOS",
    alphaCode: "SOS",
    numericCode: "706",
    name: "Somali shilling",
    rate: 24.683509264557,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.040512878022409
  },
  inr: {
    code: "INR",
    alphaCode: "INR",
    numericCode: "356",
    name: "Indian Rupee",
    rate: 3.0101378986213,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.33221069388816
  },
  huf: {
    code: "HUF",
    alphaCode: "HUF",
    numericCode: "348",
    name: "Hungarian Forint",
    rate: 12.954268833095,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.077194630811213
  },
  ngn: {
    code: "NGN",
    alphaCode: "NGN",
    numericCode: "566",
    name: "Nigerian Naira",
    rate: 13.05527269217,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.076597404250296
  },
  iqd: {
    code: "IQD",
    alphaCode: "IQD",
    numericCode: "368",
    name: "Iraqi dinar",
    rate: 49.605684096855,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.020158980129122
  },
  afn: {
    code: "AFN",
    alphaCode: "AFN",
    numericCode: "971",
    name: "Afghan afghani",
    rate: 3.3359233244046,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.29976708178042
  },
  ttd: {
    code: "TTD",
    alphaCode: "TTD",
    numericCode: "780",
    name: "Trinidad Tobago Dollar",
    rate: 0.2894652813533,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 3.4546457361824
  },
  htg: {
    code: "HTG",
    alphaCode: "HTG",
    numericCode: "332",
    name: "Haitian gourde",
    rate: 4.0893929291187,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.24453507337959
  },
  mop: {
    code: "MOP",
    alphaCode: "MOP",
    numericCode: "446",
    name: "Macanese pataca",
    rate: 0.34471883657819,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 2.9009148729045
  },
  wst: {
    code: "WST",
    alphaCode: "WST",
    numericCode: "882",
    name: "Samoan tala",
    rate: 0.11446558057552,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 8.7362506263642
  },
  myr: {
    code: "MYR",
    alphaCode: "MYR",
    numericCode: "458",
    name: "Malaysian Ringgit",
    rate: 0.17780940229855,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 5.6239995583639
  },
  nok: {
    code: "NOK",
    alphaCode: "NOK",
    numericCode: "578",
    name: "Norwegian Krone",
    rate: 0.38394719963331,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 2.6045247913126
  },
  hrk: {
    code: "HRK",
    alphaCode: "HRK",
    numericCode: "191",
    name: "Croatian Kuna",
    rate: 0.28671221125121,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 3.4878179608605
  },
  byn: {
    code: "BYN",
    alphaCode: "BYN",
    numericCode: "933",
    name: "Belarussian Ruble",
    rate: 0.088091130390892,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 11.35188066679
  },
  mad: {
    code: "MAD",
    alphaCode: "MAD",
    numericCode: "504",
    name: "Moroccan Dirham",
    rate: 0.41129981600413,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 2.431316429254
  },
  etb: {
    code: "ETB",
    alphaCode: "ETB",
    numericCode: "230",
    name: "Ethiopian birr",
    rate: 1.2536385103267,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.79767811196179
  },
  cve: {
    code: "CVE",
    alphaCode: "CVE",
    numericCode: "132",
    name: "Cape Verde escudo",
    rate: 4.3177346662773,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.23160293007587
  },
  mwk: {
    code: "MWK",
    alphaCode: "MWK",
    numericCode: "454",
    name: "Malawian kwacha",
    rate: 31.266080840165,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.031983541688902
  },
  gtq: {
    code: "GTQ",
    alphaCode: "GTQ",
    numericCode: "320",
    name: "Guatemalan Quetzal",
    rate: 0.3296236114151,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 3.0337632541155
  },
  bam: {
    code: "BAM",
    alphaCode: "BAM",
    numericCode: "977",
    name: "Bosnia and Herzegovina convertible mark",
    rate: 0.07588827490357,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 13.177266201804
  },
  rub: {
    code: "RUB",
    alphaCode: "RUB",
    numericCode: "643",
    name: "Russian Rouble",
    rate: 2.7276225144858,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.36661964574982
  },
  hkd: {
    code: "HKD",
    alphaCode: "HKD",
    numericCode: "344",
    name: "Hong Kong Dollar",
    rate: 0.33261674780401,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 3.0064631639933
  },
  amd: {
    code: "AMD",
    alphaCode: "AMD",
    numericCode: "051",
    name: "Armenia Dram",
    rate: 20.127041030469,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.049684402117836
  },
  rsd: {
    code: "RSD",
    alphaCode: "RSD",
    numericCode: "941",
    name: "Serbian Dinar",
    rate: 4.5386282803018,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.22033088815405
  },
  mkd: {
    code: "MKD",
    alphaCode: "MKD",
    numericCode: "807",
    name: "Macedonian denar",
    rate: 2.3862583755499,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.41906610375733
  },
  xpf: {
    code: "XPF",
    alphaCode: "XPF",
    numericCode: "953",
    name: "CFP Franc",
    rate: 4.6283258747235,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.21606084512356
  },
  mmk: {
    code: "MMK",
    alphaCode: "MMK",
    numericCode: "104",
    name: "Myanma Kyat",
    rate: 64.782462243462,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.015436276507087
  },
  omr: {
    code: "OMR",
    alphaCode: "OMR",
    numericCode: "512",
    name: "Omani Rial",
    rate: 0.016393037422437,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 61.001507788382
  },
  bwp: {
    code: "BWP",
    alphaCode: "BWP",
    numericCode: "072",
    name: "Botswana Pula",
    rate: 0.46513188885777,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 2.1499278461765
  },
  sek: {
    code: "SEK",
    alphaCode: "SEK",
    numericCode: "752",
    name: "Swedish Krona",
    rate: 0.41320478398157,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 2.4201075078661
  },
  mxn: {
    code: "MXN",
    alphaCode: "MXN",
    numericCode: "484",
    name: "Mexican Peso",
    rate: 0.83054151568663,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 1.2040337311414
  },
  mdl: {
    code: "MDL",
    alphaCode: "MDL",
    numericCode: "498",
    name: "Moldova Lei",
    rate: 0.75590803292535,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 1.3229122544578
  },
  ars: {
    code: "ARS",
    alphaCode: "ARS",
    numericCode: "032",
    name: "Argentine Peso",
    rate: 2.4240316274781,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.41253587150609
  },
  bzd: {
    code: "BZD",
    alphaCode: "BZD",
    numericCode: "084",
    name: "Belize dollar",
    rate: 0.086009781005782,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 11.626584654747
  },
  kmf: {
    code: "KMF",
    alphaCode: "KMF",
    numericCode: "174",
    name: "\tComoro franc",
    rate: 19.073746591696,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.052428084602707
  },
  sll: {
    code: "SLL",
    alphaCode: "SLL",
    numericCode: "694",
    name: "Sierra Leonean leone",
    rate: 401.22345719654,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.0024923767094459
  },
  mvr: {
    code: "MVR",
    alphaCode: "MVR",
    numericCode: "462",
    name: "Maldivian rufiyaa",
    rate: 0.65963065471631,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 1.5159998900143
  },
  clp: {
    code: "CLP",
    alphaCode: "CLP",
    numericCode: "152",
    name: "Chilean Peso",
    rate: 30.670483048548,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.032604638095106
  },
  aed: {
    code: "AED",
    alphaCode: "AED",
    numericCode: "784",
    name: "U.A.E Dirham",
    rate: 0.15577108747548,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 6.4196765664707
  },
  xof: {
    code: "XOF",
    alphaCode: "XOF",
    numericCode: "952",
    name: "West African CFA Franc",
    rate: 25.407617405451,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.039358275277928
  },
  uzs: {
    code: "UZS",
    alphaCode: "UZS",
    numericCode: "860",
    name: "Uzbekistan Sum",
    rate: 398.63534939075,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.0025085582639079
  },
  ves: {
    code: "VES",
    alphaCode: "VES",
    numericCode: "928",
    name: "Venezuelan Bolivar",
    rate: 841.13137259578,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.0011888749279603
  },
  jmd: {
    code: "JMD",
    alphaCode: "JMD",
    numericCode: "388",
    name: "Jamaican Dollar",
    rate: 5.7472549274101,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.1739961099047
  },
  gip: {
    code: "GIP",
    alphaCode: "GIP",
    numericCode: "292",
    name: "Gibraltar pound",
    rate: 0.034331358139194,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 29.127889317561
  },
  mzn: {
    code: "MZN",
    alphaCode: "MZN",
    numericCode: "943",
    name: "Mozambican metical",
    rate: 2.629346921968,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.38032257806874
  },
  kes: {
    code: "KES",
    alphaCode: "KES",
    numericCode: "404",
    name: "Kenyan shilling",
    rate: 4.4312142390489,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.22567177889703
  },
  dkk: {
    code: "DKK",
    alphaCode: "DKK",
    numericCode: "208",
    name: "Danish Krone",
    rate: 0.28904075179366,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 3.4597197585269
  },
  bgn: {
    code: "BGN",
    alphaCode: "BGN",
    numericCode: "975",
    name: "Bulgarian Lev",
    rate: 0.075756225306263,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 13.200235306831
  },
  pgk: {
    code: "PGK",
    alphaCode: "PGK",
    numericCode: "598",
    name: "Papua New Guinean kina",
    rate: 0.14434168420809,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 6.9280056241988
  },
  egp: {
    code: "EGP",
    alphaCode: "EGP",
    numericCode: "818",
    name: "Egyptian Pound",
    rate: 0.69241511164846,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 1.4442203573796
  },
  nio: {
    code: "NIO",
    alphaCode: "NIO",
    numericCode: "558",
    name: "Nicaraguan C\u00f3rdoba",
    rate: 1.4270268073307,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.70075768364192
  },
  srd: {
    code: "SRD",
    alphaCode: "SRD",
    numericCode: "968",
    name: "Surinamese dollar",
    rate: 0.31893278187352,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 3.1354569264585
  },
  syp: {
    code: "SYP",
    alphaCode: "SYP",
    numericCode: "760",
    name: "Syrian pound",
    rate: 18.694367675971,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.053492047301785
  },
  top: {
    code: "TOP",
    alphaCode: "TOP",
    numericCode: "776",
    name: "Tongan pa\u02bbanga",
    rate: 0.098745499101078,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 10.12704385621
  },
  ugx: {
    code: "UGX",
    alphaCode: "UGX",
    numericCode: "800",
    name: "Ugandan shilling",
    rate: 156.79147083072,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.0063778979475205
  },
  cny: {
    code: "CNY",
    alphaCode: "CNY",
    numericCode: "156",
    name: "Chinese Yuan",
    rate: 0.3024250584471,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 3.3066043043352
  },
  kwd: {
    code: "KWD",
    alphaCode: "KWD",
    numericCode: "414",
    name: "Kuwaiti Dinar",
    rate: 0.012895720441521,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 77.545105334344
  },
  isk: {
    code: "ISK",
    alphaCode: "ISK",
    numericCode: "352",
    name: "Icelandic Krona",
    rate: 5.2586154088379,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.19016412539303
  },
  pkr: {
    code: "PKR",
    alphaCode: "PKR",
    numericCode: "586",
    name: "Pakistani Rupee",
    rate: 6.5809624793847,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.15195345713223
  },
  lyd: {
    code: "LYD",
    alphaCode: "LYD",
    numericCode: "434",
    name: "Libyan Dinar",
    rate: 0.058765569249206,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 17.016767007894
  },
  pab: {
    code: "PAB",
    alphaCode: "PAB",
    numericCode: "590",
    name: "Panamanian Balboa",
    rate: 0.042683346510272,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 23.42834106879
  },
  sbd: {
    code: "SBD",
    alphaCode: "SBD",
    numericCode: "090",
    name: "Solomon Islands dollar",
    rate: 0.34222238151546,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 2.922076561947
  },
  lak: {
    code: "LAK",
    alphaCode: "LAK",
    numericCode: "418",
    name: "Lao kip",
    rate: 376.36890675075,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.0026569676242205
  },
  cup: {
    code: "CUP",
    alphaCode: "CUP",
    numericCode: "192",
    name: "Cuban peso",
    rate: 0.042683346510272,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 23.42834106879
  },
  vuv: {
    code: "VUV",
    alphaCode: "VUV",
    numericCode: "548",
    name: "Vanuatu vatu",
    rate: 5.0598954775686,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.19763254091575
  },
  nzd: {
    code: "NZD",
    alphaCode: "NZD",
    numericCode: "554",
    name: "New Zealand Dollar",
    rate: 0.0673687004732,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 14.843688433589
  },
  sar: {
    code: "SAR",
    alphaCode: "SAR",
    numericCode: "682",
    name: "Saudi Riyal",
    rate: 0.15917352720512,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 6.2824517214557
  },
  brl: {
    code: "BRL",
    alphaCode: "BRL",
    numericCode: "986",
    name: "Brazilian Real",
    rate: 0.17665720622014,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 5.6606804862172
  },
  azn: {
    code: "AZN",
    alphaCode: "AZN",
    numericCode: "944",
    name: "Azerbaijan Manat",
    rate: 0.072036014639366,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 13.881945093802
  },
  ghs: {
    code: "GHS",
    alphaCode: "GHS",
    numericCode: "936",
    name: "Ghanaian Cedi",
    rate: 0.22842046078542,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 4.3778915275869
  },
  gmd: {
    code: "GMD",
    alphaCode: "GMD",
    numericCode: "270",
    name: "Gambian dalasi",
    rate: 2.1866162705827,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.45732761319548
  },
  bif: {
    code: "BIF",
    alphaCode: "BIF",
    numericCode: "108",
    name: "Burundian franc",
    rate: 78.795157874634,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.012691135178522
  },
  aoa: {
    code: "AOA",
    alphaCode: "AOA",
    numericCode: "973",
    name: "Angolan kwanza",
    rate: 15.607224390031,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.064072891823017
  },
  qar: {
    code: "QAR",
    alphaCode: "QAR",
    numericCode: "634",
    name: "Qatari Rial",
    rate: 0.15631603955833,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 6.3972961624762
  },
  zar: {
    code: "ZAR",
    alphaCode: "ZAR",
    numericCode: "710",
    name: "South African Rand",
    rate: 0.63811932876605,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 1.5671050145648
  },
  ils: {
    code: "ILS",
    alphaCode: "ILS",
    numericCode: "376",
    name: "Israeli New Sheqel",
    rate: 0.14870394109841,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 6.724771331637
  },
  kgs: {
    code: "KGS",
    alphaCode: "KGS",
    numericCode: "417",
    name: "Kyrgyzstan Som",
    rate: 2.9559175328143,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.33830443133098
  },
  jod: {
    code: "JOD",
    alphaCode: "JOD",
    numericCode: "400",
    name: "Jordanian Dinar",
    rate: 0.030170026412141,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 33.145479766554
  },
  bsd: {
    code: "BSD",
    alphaCode: "BSD",
    numericCode: "044",
    name: "Bahamian Dollar",
    rate: 0.042683346510272,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 23.42834106879
  },
  gnf: {
    code: "GNF",
    alphaCode: "GNF",
    numericCode: "324",
    name: "Guinean franc",
    rate: 393.79339317438,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.0025394026850958
  },
  hnl: {
    code: "HNL",
    alphaCode: "HNL",
    numericCode: "340",
    name: "Honduran Lempira",
    rate: 1.0501157151317,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.95227600691091
  },
  lkr: {
    code: "LKR",
    alphaCode: "LKR",
    numericCode: "144",
    name: "Sri Lanka Rupee",
    rate: 7.7531102839911,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.12898049471382
  },
  thb: {
    code: "THB",
    alphaCode: "THB",
    numericCode: "764",
    name: "Thai Baht",
    rate: 1.2982624865722,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.77026025964929
  },
  xaf: {
    code: "XAF",
    alphaCode: "XAF",
    numericCode: "950",
    name: "Central African CFA Franc",
    rate: 25.409551885298,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.039355278853957
  },
  tjs: {
    code: "TJS",
    alphaCode: "TJS",
    numericCode: "972",
    name: "Tajikistan Ruble",
    rate: 0.40981932147135,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 2.4400996917611
  },
  uyu: {
    code: "UYU",
    alphaCode: "UYU",
    numericCode: "858",
    name: "Uruguayan Peso",
    rate: 1.566744386809,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.63826620884642
  },
  gyd: {
    code: "GYD",
    alphaCode: "GYD",
    numericCode: "328",
    name: "Guyanese dollar",
    rate: 8.9020798457005,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.11233329933375
  },
  rwf: {
    code: "RWF",
    alphaCode: "RWF",
    numericCode: "646",
    name: "Rwandan franc",
    rate: 39.388457015822,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.025388148604001
  },
  lsl: {
    code: "LSL",
    alphaCode: "LSL",
    numericCode: "426",
    name: "Lesotho loti",
    rate: 0.63978467789144,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 1.5630258656643
  },
  scr: {
    code: "SCR",
    alphaCode: "SCR",
    numericCode: "690",
    name: "Seychelles rupee",
    rate: 0.58460050120734,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 1.7105698642659
  },
  bhd: {
    code: "BHD",
    alphaCode: "BHD",
    numericCode: "048",
    name: "Bahrain Dinar",
    rate: 0.016088554919669,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 62.155986351358
  },
  vnd: {
    code: "VND",
    alphaCode: "VND",
    numericCode: "704",
    name: "Vietnamese Dong",
    rate: 984.43583305969,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.0010158102401575
  },
  irr: {
    code: "IRR",
    alphaCode: "IRR",
    numericCode: "364",
    name: "Iranian rial",
    rate: 1786.4209519942,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.00055977847711857
  },
  crc: {
    code: "CRC",
    alphaCode: "CRC",
    numericCode: "188",
    name: "Costa Rican Col\u00f3n",
    rate: 24.784380600476,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.040347992395694
  },
  nad: {
    code: "NAD",
    alphaCode: "NAD",
    numericCode: "516",
    name: "Namibian dollar",
    rate: 0.63954415733585,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 1.5636136903599
  },
  sdg: {
    code: "SDG",
    alphaCode: "SDG",
    numericCode: "938",
    name: "Sudanese pound",
    rate: 1.9244202019382,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.51963703093164
  },
  mru: {
    code: "MRU",
    alphaCode: "MRU",
    numericCode: "929",
    name: "Mauritanian ouguiya",
    rate: 1.5854496351476,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.63073589840457
  },
  tzs: {
    code: "TZS",
    alphaCode: "TZS",
    numericCode: "834",
    name: "Tanzanian shilling",
    rate: 98.107696569397,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.01019288022212
  },
  krw: {
    code: "KRW",
    alphaCode: "KRW",
    numericCode: "410",
    name: "South Korean Won",
    rate: 50.878270489034,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.019654756154015
  },
  ron: {
    code: "RON",
    alphaCode: "RON",
    numericCode: "946",
    name: "Romanian New Leu",
    rate: 0.18380719328517,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 5.4404834877628
  },
  pen: {
    code: "PEN",
    alphaCode: "PEN",
    numericCode: "604",
    name: "Peruvian Nuevo Sol",
    rate: 0.14219399642977,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 7.0326457171765
  },
  lbp: {
    code: "LBP",
    alphaCode: "LBP",
    numericCode: "422",
    name: "Lebanese Pound",
    rate: 63.254464319323,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.015809160835696
  },
  mga: {
    code: "MGA",
    alphaCode: "MGA",
    numericCode: "969",
    name: "Malagasy ariary",
    rate: 161.55626386641,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.0061897940449209
  },
  xcd: {
    code: "XCD",
    alphaCode: "XCD",
    numericCode: "951",
    name: "East Caribbean Dollar",
    rate: 0.11562478478307,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 8.648664746716
  },
  ang: {
    code: "ANG",
    alphaCode: "ANG",
    numericCode: "532",
    name: "Neth. Antillean Guilder",
    rate: 0.07531377096305,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 13.277784224755
  },
  dop: {
    code: "DOP",
    alphaCode: "DOP",
    numericCode: "214",
    name: "Dominican Peso",
    rate: 2.2179758259626,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.45086154154363
  },
  mnt: {
    code: "MNT",
    alphaCode: "MNT",
    numericCode: "496",
    name: "Mongolian togrog",
    rate: 114.17365493378,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.0087585879647977
  },
  mur: {
    code: "MUR",
    alphaCode: "MUR",
    numericCode: "480",
    name: "Mauritian Rupee",
    rate: 1.5389500735325,
    date: "Thu, 26 Sep 2019 12:00:01 GMT",
    inverseRate: 0.64979365945552
  },
  czk: { rate: 1 }
};

const convertPrice = (price, currency) => {
  try {
    const rate = exchangeRatesCZK[currency.toLowerCase()].rate;
    return price * rate;
  } catch (error) {
    const rate = exchangeRatesCZK["eur"].rate;
    return price * rate;
  }
};

export default convertPrice;
