import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { langKeys } from "./common/utils/languageValueToName";

if (
  process.env.REACT_APP_VENDOR === "wietholt" ||
  process.env.REACT_APP_VENDOR === "ta" ||
  process.env.REACT_APP_VENDOR === "utax"
) {
  i18n.init({
    lng: "de"
  });
}
const languages = [];
langKeys.map(language => languages.push(language));
i18n
  .use(LanguageDetector)
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // // pass the i18next instance to react-i18next.
  .use(initReactI18next)
  // // init i18next
  // // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    detection: {
      order: ["path", "localStorage", "navigator"],

      // keys or params to lookup language from
      lookupQuerystring: "lng",
      lookupCookie: "i18next",
      lookupLocalStorage: "i18nextLng",
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,

      // only detect languages that are in the whitelist
      checkWhitelist: true
    },
    backend: {
      // path where resources get loaded from, or a function
      // returning a path:
      // function(lngs, namespaces) { return customPath; }
      // the returned path will interpolate lng, ns if provided like giving a static path
      loadPath: "/locales/{{lng}}.json"
    },
    fallbackLng: "en",
    whitelist: languages,
    debug: false,
    preload: languages,
    keySeparator: false,
    nsSeparator: false,
    load: "languageOnly",
    cleanCode: true,
    useSuspense: true,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  },
  () => {
    localStorage.setItem("appLng", localStorage.i18nextLng);
  }
  );

export default i18n;
