const languageToCountryInfo = {
  bs: {
    countryCode: "BA",
    currency: "BAM"
  },
  cs: {
    countryCode: "CZ",
    currency: "CZK"
  },
  da: {
    countryCode: "DK",
    currency: "DKK"
  },
  de: {
    countryCode: "DE",
    currency: "EUR"
  },
  et: {
    countryCode: "EE",
    currency: "EUR"
  },
  en: {
    countryCode: "GB",
    currency: "GBP"
  },
  "en-US": {
    countryCode: "US",
    currency: "USD"
  },
  es: {
    countryCode: "ES",
    currency: "EUR"
  },
  "es-US": {
    countryCode: "ES",
    currency: "EUR"
  },
  "es-MX": {
    countryCode: "MX",
    currency: "MXN"
  },
  fr: {
    countryCode: "FR",
    currency: "EUR"
  },
  hr: {
    countryCode: "HR",
    currency: "HRK"
  },
  is: {
    countryCode: "IS",
    currency: "ISK"
  },
  it: {
    countryCode: "IT",
    currency: "EUR"
  },
  lv: {
    countryCode: "LV",
    currency: "EUR"
  },
  lt: {
    countryCode: "LT",
    currency: "EUR"
  },
  hu: {
    countryCode: "HU",
    currency: "HUF"
  },
  no: {
    countryCode: "NO",
    currency: "NOK"
  },
  pl: {
    countryCode: "PL",
    currency: "PLN"
  },
  "pt-BR": {
    countryCode: "PT",
    currency: "EUR"
  },
  pt: {
    countryCode: "PT",
    currency: "EUR"
  },
  sk: {
    countryCode: "SK",
    currency: "EUR"
  },
  sl: {
    countryCode: "SI",
    currency: "EUR"
  },
  tr: {
    countryCode: "TR",
    currency: "TRY"
  },
  bg: {
    countryCode: "BG",
    currency: "BGN"
  },
  kk: {
    countryCode: "KZ",
    currency: "KZT"
  },
  ru: {
    countryCode: "RU",
    currency: "RUB"
  },
  sr: {
    countryCode: "RS",
    currency: "RSD"
  },
  ar: {
    countryCode: "AE",
    currency: "AED"
  },
  ko: {
    countryCode: "KR",
    currency: "KRW"
  },
  "zn-cn": {
    countryCode: "CN",
    currency: "CNY"
  },
  "zn-tw": {
    countryCode: "CN",
    currency: "CNY"
  },
  ja: {
    countryCode: "JP",
    currency: "JPY"
  }
};

export default language => {
  try {
    return languageToCountryInfo[language];
  } catch (error) {
    return languageToCountryInfo["en-US"];
  }
};
