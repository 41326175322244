import { ActionTypes } from "../constants/";
import {
  FAVORITE,
  PRINTED,
  READY,
  PRINT,
  DUPLICATE,
  UNMARK_FAVORITE,
  MARK_FAVORITE,
  DELETE,
  SETTINGS
} from "../constants/General";

const initialState = {
  jobs: [],
  tabs: [
    { name: "Printed", active: false, action: PRINTED, visible: true },
    { name: "Favorite", active: false, action: FAVORITE, visible: true },
    { name: "Ready", active: true, action: READY, visible: true }
  ],
  actions: [
    { action: DELETE, name: "Selected", active: false, visible: true },
    { action: MARK_FAVORITE, name: "Selected", active: false, visible: true },
    {
      action: UNMARK_FAVORITE,
      name: "Selected",
      active: false,
      visible: false
    },
    { action: SETTINGS, name: "Selected", active: false, visible: true },
    { action: DUPLICATE, name: "Selected", active: false, visible: false },
    { action: PRINT, name: "Selected", active: false, visible: true }
  ],
  actionMenu: false,
  colors: {
    tabs: "black",
    actions: "cyan"
  }
};

export default function jobs(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_JOBS_SUCCESS:
      return {
        ...initialState,
        jobs: action.jobs,
        actionMenu: false,
        formattedSum: action.formattedSum
      };

    // ACTION FROM DASHBOARD
    case ActionTypes.PRINT_ALL:
      return {
        ...state,
        jobs: state.jobs.map(job => {
          return { ...job, printed: true };
        })
      };

    case ActionTypes.PRINT_JOBS_REQUEST:
      return {
        ...state,
        jobs: state.jobs.map(job => {
          if (!job.selected) {
            return job;
          }
          return { ...job, animation: "k42-print", selected: true };
        })
      };

    case ActionTypes.PRINT_JOBS_SUCCESS:
      return {
        ...state,
        jobsCount: action.jobsCount,
        jobs: state.jobs.map(job => {
          if (!job.selected) {
            return job;
          }
          return { ...job, printed: true, animation: null, selected: false };
        })
      };

    case ActionTypes.DUPLICATE_JOBS_REQEUST:
      return {
        ...state,
        jobs: state.jobs.map(job => {
          if (!job.selected) {
            return job;
          }
          return { ...job, animation: "k42-print", selected: true };
        })
      };

    case ActionTypes.DUPLICATE_JOBS_SUCCESS:
      return {
        ...state,
        jobs: [
          ...state.jobs.map(job => ({
            ...job,
            animation: null,
            selected: false
          })),
          ...state.jobs
            .filter(job => job.selected)
            .map(job => {
              return {
                ...job,
                id: Math.random()
                  .toString(36)
                  .substr(2, 5),
                selected: false,
                animation: null,
                favorite: false
              };
            })
        ]
      };

    case ActionTypes.DELETE_JOBS_REQUEST:
      console.log("delete requested");
      return {
        ...state,
        jobs: state.jobs.map(job => {
          if (!job.selected) {
            return job;
          }
          return { ...job, animation: "k42-delete", selected: true };
        })
      };

    case ActionTypes.DELETE_JOBS_SUCCESS:
      console.log("deleted");
      return {
        ...state,
        jobs: state.jobs.map(job => {
          if (!job.selected) {
            return job;
          }
          return { ...job, printed: true, animation: null, selected: false };
        })
      };

    case ActionTypes.FAVORITE_JOBS_REQUEST:
      return {
        ...state,
        jobs: state.jobs.map(job => {
          if (!job.selected) {
            return job;
          }
          return { ...job, animation: "k42-favorite", selected: true };
        })
      };

    case ActionTypes.FAVORITE_JOBS_SUCCESS:
      return {
        ...state,
        jobs: state.jobs.map(job => {
          if (!job.selected) {
            return job;
          }
          return { ...job, favorite: true, animation: null, selected: false };
        })
      };

    case ActionTypes.UNFAVORITE_JOBS_REQUEST:
      return {
        ...state,
        jobs: state.jobs.map(job => {
          if (!job.selected) {
            return job;
          }
          return { ...job, animation: "k42-favorite", selected: true };
        })
      };

    case ActionTypes.UNFAVORITE_JOBS_SUCCESS:
      return {
        ...state,
        jobs: state.jobs.map(job => {
          if (!job.selected) {
            return job;
          }
          return { ...job, favorite: false, animation: null, selected: false };
        })
      };

    case ActionTypes.TOGGLE_JOB_SUCCESS:
      return {
        ...state,
        jobs: state.jobs.map(job => {
          if (job.id === action.job.id) {
            return { ...job, selected: !job.selected };
          }
          return job;
        })
      };

    case ActionTypes.TOGGLE_ALL_JOBS_SUCCESS:
      let allSelected = state.jobs.reduce((a, b) => !!a && b.selected);
      return {
        ...state,
        jobs: state.jobs.map(job => {
          return { ...job, selected: !allSelected };
        })
      };

    case ActionTypes.SELECT_TAB_SUCCESS:
      return {
        ...state,
        // Mark selected tab as active
        tabs: state.tabs.map(tab => {
          if (tab.action === action.tab) {
            return { ...tab, active: true };
          }
          return { ...tab, active: false };
        }),
        // Set tab visibility based on active tab
        actions: state.actions.map(actionTab => {
          let favorite = action.tab === FAVORITE;
          let printed = action.tab === PRINTED;
          switch (actionTab.action) {
            case MARK_FAVORITE:
              return { ...actionTab, visible: !favorite };
            case UNMARK_FAVORITE:
              return { ...actionTab, visible: favorite };
            case PRINT:
              return { ...actionTab, visible: !printed };
            case DUPLICATE:
              return { ...actionTab, visible: printed };
            default:
              return actionTab;
          }
        })
      };

    case ActionTypes.LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
}
