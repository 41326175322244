import { ActionTypes } from "../constants/";
import createSteps from "../common/utils/steps";

export const init = () => async dispatch => {
  const steps = await createSteps();
  dispatch({
    type: ActionTypes.INIT_GUIDE,
    message: "Guide inited",
    steps: steps
  });
};

export const next = () => ({
  type: ActionTypes.GUIDE_NEXT_STEP,
  message: "Next step in guide"
});

export const prev = () => ({
  type: ActionTypes.GUIDE_BACK_STEP,
  message: "Prev step in guide"
});

export const skip = () => ({
  type: ActionTypes.SKIP_GUIDE,
  message: "Prev step in guide"
});
