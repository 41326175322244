export default width => {
  switch (width) {
    case 600:
      return process.env.PUBLIC_URL + "/img/" + process.env.REACT_APP_VENDOR + "_logo_600.png";
    case 1024:
      return process.env.PUBLIC_URL + "/img/" + process.env.REACT_APP_VENDOR + "_logo_1024.png";
    case 1920:
      return process.env.PUBLIC_URL + "/img/" + process.env.REACT_APP_VENDOR + "_logo_1920.png";
    default:
      return process.env.PUBLIC_URL + "/img/" + process.env.REACT_APP_VENDOR + "_logo_1024.png";
  }
};
