const nameToValue = {
  "Bosanski (Bosna I Hercegovina)": "bs",
  "Čeština (Česká Republika)": "cs",
  "Dansk (Danmark)": "da",
  "Deutsch (Deutschland)": "de",
  "Eesti (Eesti)": "et",
  "English (United Kingdom)": "en",
  "English (United States)": "en-US",
  "Español (España)": "es",
  //"Español (Estados Unidos)": "es-us",
  "Français (France)": "fr",
  "Hrvatski (Hrvatska)": "hr",
  "Íslenska (Ísland)": "is",
  "Italiano (Italia)": "it",
  "Latviešu (Latvija)": "lv",
  "Lietuvių (Lietuva)": "lt",
  "Magyar (Magyarország)": "hu",
  "Norsk (Norge)": "no",
  "Polski (Polska)": "pl",
  "Português (Brasil)": "pt-BR",
  "Português (Portugal)": "pt",
  "Slovenčina (Slovensko)": "sk",
  "Slovenščina (Slovenija)": "sl",
  "Türkçe (Türkiye)": "tr",
  "Български (България)": "bg",
  "Қазақ Тілі (Қазақстан)": "kk",
  "Русский (Россия)": "ru",
  "Српски (Србија)": "sr",
  "العربية (المملكة العربية السعودية)": "ar",
  "한국어(대한민국)": "ko",
  "中文（简体）": "zn-cn",
  "中文（繁體）": "zn-tw",
  "日本語 (日本)": "ja"
};

export default name => nameToValue[name];
