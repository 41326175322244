import React, { Component } from "react";

export default class FlagImage extends Component {
  render() {
    const { name, big } = this.props;
    return name ? (
      big ? (
        <img
          alt={`Flag ${name}`}
          src={`${process.env.PUBLIC_URL}/flags/large/${name}.svg`}
          style={{ height: "50px", minWidth: "75px" }}
        />
      ) : (
        <img alt={`Flag ${name}`} src={`${process.env.PUBLIC_URL}/flags/small/${name}.png`} />
      )
    ) : (
      <div />
    );
  }
}
//
